@import "semantic-ui-css/semantic.min.css";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');



*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.hr-div, .hr-div-three, .hr-div-four{
  display: flex;
  justify-content: center;
}


.ui.text{
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: black;
  background: linear-gradient(transparent, #ffffff), url('../assets/mountain.jpeg') no-repeat center; 
  background-size: cover; 
}
.ui.inverted.header{
  margin-top: 200px;
  display: flex;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  font-size: 100px;
  text-shadow: 2.5px 2.5px 2.5px #000, 
  5px 5px 5px rgb(255, 255, 255); 
  position: relative;
  // animation:mymove 3s ease-out forwards;
  // animation-iteration-count: 1;
  color: white;
  z-index: 0;
  font-weight: normal;
}
.ui.text h2{
  display: flex;
  justify-content: center;
  text-shadow: 2.5px 2px 2.5px #000, 
  5px 5px 5px rgb(255, 255, 255); 
  font-family: 'Raleway', sans-serif;
  font-size: 40px;
}
.arrows{
  display: flex;
  justify-content: center;
  margin-right: -20px;
}
.arrow{
  color: white;
  text-shadow: -1px -3px 8px #000000;
  transform: rotate(1deg);
}

.episode{
  text-decoration: underline;
}

// EXPERIENCE
.ui.stackable.four.column.centered.grid{
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.experience{
  font-family: 'Raleway', sans-serif;
}
.sei{
  display: flex;
  justify-content: center;
  font-size: 25px;
  // text-decoration: underline;
  font-weight: bold;
}
.four.column.centered.row{
  display: flex;
  justify-content: center;
}
.spire-logo{
  display: flex;
  justify-content: center;
  height: 75px;
  margin-top: -5px;
  
}
.exp-hr{
  display: flex;
  justify-content: center;
  width: 100px;
}

.sei-details{
  display: flex;
  justify-content: center;
  margin-top: -10px;
  font-size: 21px;
  font-weight: bold;

}
.sei-description{
  font-weight: bold;
  text-align: justify;
  margin: -10px 50px 0px 50px;
  font-size: 19px;
}
.ga-logo{
  display: flex;
  justify-content: center;
}

.ga-logo, .liv-logo {
  margin-bottom: 20px;
  height: 65px;
}
.exp-logo{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 50px;
}
.exp-logo-liv{
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
  margin-top: 30px;
}
.column.desc{
  margin: 50px;
  margin-top: 50px;
}
// .column-desc{
//   margin: 200px;
// }

.experience-me{
  display: flex;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  font-size: 70px;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2.5px 2.5px #000, 
  3px 3px 5px rgb(255, 255, 255); 
}

.exp-column{
  margin: 100px;
  margin-top: 20px;
}


// PROJECTS
// .slider-settings{
//   width: 600px;
// }
.project-title{
  display: flex;
  justify-content: center;
  font-size: 70px;
  // margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 70px;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2.5px 2.5px #000, 
  3px 3px 5px rgb(255, 255, 255); 
}
.nine.wide.column{
  display: flex;
  justify-content: center;
  // margin: 60px 0 60px 0;
}
.project-four-title, .project-three-title, .project-two-title, .project-one-title{
  display: flex;
  justify-content: center;
  font-size: 35px;
  font-family: 'Raleway', sans-serif;
}
.project-details-four{
  margin-top: 60px;
  font-family: 'Raleway', sans-serif;
}
.project-four-description{
  display: flex;
  justify-content: center;
  text-align: justify;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  font-weight: bold;
}
.project-links{
  font-size: 19px;
  font-weight: bold;

}
.readme, .deploy{
  text-decoration: underline;
  font-weight: bold;
}

.ui.stackable.grid.container{
  display: flex;
  justify-content: center;
}
.project-logo.frodder, .project-logo.lotr, .project-logo.jetflix, .project-logo {
  display: flex;
  justify-content: center;
}

// CONTACT
footer{
  background-color: black;
  display: flex;
  justify-content: center;
  // height: 20vh;
  // margin: -20px;
}
.contact-title{
  display: flex;
  justify-content: center;
  // font-size: 60px;
  margin-bottom: 20px;
  font-family: 'Raleway', sans-serif;
  font-size: 70px;
  color: rgb(255, 255, 255);
  text-shadow: 2px 2.5px 2.5px #000, 
  3px 3px 5px rgb(255, 255, 255); 
  font-weight: bold;
}
.ui.list.contact{
  display: flex;
  justify-content: space-between;
  margin: -15px -25px 10px -25px;
}
.js{
  display: flex;
  justify-content: center;
  color: white;
  padding: 10px;
  margin-top: -15px;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
}

.fade-in {
  animation: fadeIn ease 4s;
  -webkit-animation: fadeIn ease 4s;
  -moz-animation: fadeIn ease 4s;
  -o-animation: fadeIn ease 4s;
  -ms-animation: fadeIn ease 4s;
}

.box {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  height: 200px;
  margin: 10 auto 0 auto;
  transform-origin: bottom;
  width: 200px;
}
.bounce-2 {
  animation-name: bounce-2;
  animation-timing-function: ease;
}
@keyframes bounce-2 {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(15px); }
  100% { transform: translateY(0); }
}

.arrows {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}



//BURGER
.burger{
  padding: 1.5rem;
  padding-left: .5rem;
  
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}
html,
body {
  margin: 0;
}

#App {
  font-family: sans-serif;
}

#page-wrap {
  text-align: center;
  overflow: auto;
  top: 45%;
  position: relative;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 20px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  // width: 150px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
  
}

/* Wrapper for item list */
.bm-item-list {
  color: #ffea04;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
#burger-item{
  font-size: 22px;
}


@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}

@keyframes fadeIn {
  0% {opacity:0;}
  20% {opacity:0;}
  40% {opacity:0;}
  50% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  20% {opacity:0;}
  40% {opacity:0;}
  50% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  20% {opacity:0;}
  40% {opacity:0;}
  50% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  20% {opacity:0;}
  40% {opacity:0;}
  50% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  20% {opacity:0;}
  40% {opacity:0;}
  50% {opacity:0;}
  60% {opacity:0;}
  100% {opacity:1;}
}


@keyframes mymove
{ from {top:-200px; opacity: 0;}
to {top:10px; opacity: 1}
}

@-webkit-keyframes mymove /* Safari and Chrome */
{
from {top:0px; opacity: 0;}
to {top:200px; opacity: 1;}
}

.ui.centered.grid.aligned.fixed.menu.navbar{
  background-color: black;
}
.item{
  font-size: 20px;
  color: white;
  font-family: 'Raleway', sans-serif;
}

.ui.divided.grid{
  display: flex;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
}
.about-title{
  font-family: 'Raleway', sans-serif;
  font-size: 70px;
  color: rgb(0, 0, 0);
  text-shadow: 2px 2.5px 2.5px #000, 
  3px 3px 5px rgb(255, 255, 255); 
}
.one.column.row{
  display: flex;
  justify-content: center;
}
.column{
  display: flex;
  justify-content: center;
  margin: 0px 200px 0px 200px;
}
.about-me{
  font-size: 20px;
  text-align: justify;
  color: black;
  font-weight: bold;
}
.icons{
  display: flex;
  justify-content: center;
  margin: 0 20px 15px 20px;
}
.column.icon{
  // margin: 0px;
  display: flex;
  flex-direction: row;
}
.icon{
  display: flex;
  // justify-content: center;
}
.icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.development, .management{
  font-size: 30px;
  font-weight: bold;
}
#management-column{
  margin-top: 40px;
}
.icon{
  margin: 25px;

}

.card{
  display: flex;
  // flex-direction: column;
  justify-content: center;
}

.two.column.row{
  margin: 0px;
}
.column.design{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.name{
  display: flex;
  justify-content: center;
  flex-direction: column;

}
.names{
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: bolder;
}
#currently{
  margin-top: -10px;
}
.separator{
  display: flex;
  justify-content: center;
  margin: 70px 0px 40px 0px;
}




@media only screen and (max-width: 1500px) and (min-width: 1301px) {
  .column{
    margin: 0px 140px 0px 140px;
  }
  }

@media only screen and (max-width: 1300px) and (min-width: 801px) {
  .column{
    margin: 0px 60px 0px 60px;
  }
  }



  @media only screen and (max-width: 800px) and (min-width: 320px) {
    .column{
      display: flex;
      justify-content: center;
      margin: 0px 20px 0px 20px;
    }
    }


    @media only screen and (max-width: 480px) and (min-width: 320px) {
      .ui.inverted.header{
        font-size: 50px;
        margin-top: 0px;
      }
      .ui.text h2{
        font-size: 25px;
      }
    }
  
  
      @media only screen and (max-width: 767px) {
        .project-hr{
          width: 100px;
          color: black;
          height: 10px;
        }
      }
  
  





// .ui.sizer.vertical{
//   margin-top: 55px;
//   background: linear-gradient(transparent, #ffffff), url('../assets/meteor.jpg') no-repeat center; 
//   background-size: cover; 
// }
// .ui.huge.header{
//   margin-top: 200px;
//   display: flex;
//   justify-content: center;
//   font-family: 'Raleway', sans-serif;
//   font-size: 100px;
//   text-shadow: 1px 1px 1px #000, 
//   3px 3px 5px rgb(255, 255, 255); 
//   position: relative;
//   animation:mymove 3s ease-out forwards;
//   animation-iteration-count: 1;
//   color: white;
//   z-index: 0;
// }
// .ui.large.header{
//   display: flex;
//   justify-content: center;
//   font-family: 'Raleway', sans-serif;
//   text-shadow: 1px 1px 1px #000, 
//   3px 3px 5px rgb(255, 255, 255); 
//   // height: 150px;
//   font-size: 40px;
//   position: relative;
//   color: white;
//   z-index: 0;
// }

// @media only screen and (max-width: 800px) and (min-width: 481px) {
  
//   .ui.huge.header{
//     font-size: 70px;      
//   }
//   .ui.large.header{
//   font-size: 30px;

//   }
//   }

//   @media only screen and (max-width: 480px) and (min-width: 320px) {
  
//     .ui.huge.header{
//       font-size: 45px;      
//     }
//     .ui.large.header{
//       font-size: 24px;
    
//       }
//     }